<template>
  <v-container
    fluid
    class="mx-0 my-0 px-4 py-5"
    style="background: #E6E6E6;"
  >
    <v-row class="mt-4 ml-4">
      <v-col class="px-0 py-0">
        <div class="d-flex align-items-center">
          <v-icon
            style="
            color: #7A1878;
            cursor: pointer;
          "
            @click="navBack"
          >
            mdi-chevron-left
          </v-icon>
          <span
            class="font-common"
            style="
            color: #7A1878;
            cursor: pointer;
          "
            @click="navBack"
          >
            Kembali
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="ml-5">
      <v-col class="px-0 py-0">
        <div>
          <span
            class="font-common"
            style="
            font-size: 24px;
            color: #333333;
            "
          >
            Detail Perusahaan
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-6"
            elevation="0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common"
                style="font-weight: bold;"
              >
                Profile
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common py-0"
              >
                Nama Badan
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common py-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.name_legal }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                Nama Merek
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                Info Bank
              </v-col>

              <v-col
                cols="12"
                sm="6"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.bank_name + ' a/n ' + partnerCompanyDetail.bank_owner + ' - ' + partnerCompanyDetail.bank_number }}
                <template v-if="partnerCompanyDetail.bank_verified">
                  <span class="pl-1">
                    <v-icon color="green">
                      mdi-check-circle
                    </v-icon>
                  </span>
                </template>
                <template v-else>
                  <span class="pl-1">
                    <v-icon color="red">
                      mdi-close-circle
                    </v-icon>
                  </span>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                No.Telp Kantor
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.phone }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                Alamat
              </v-col>

              <v-col
                cols="12"
                sm="6"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.address + ', ' + partnerCompanyDetail.akot_string + ', ' + partnerCompanyDetail.apro_string }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="12"
                class="pt-4 pb-0"
              >
                <hr style="border: 1px solid #F2F2F2;">
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                Nama Direktur
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.director_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                No. Telp
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.director_phone }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="12"
                class="pt-4 pb-0"
              >
                <hr style="border: 1px solid #F2F2F2;">
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                Kontrak Hingga
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                {{ partnerCompanyDetail.contract_validity_date }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0"
              >
                Verifikasi Perusahaan
              </v-col>

              <v-col
                cols="12"
                sm="4"
                class="font-common pt-4 pb-0"
                style="font-weight: bold;"
              >
                <template v-if="partnerCompanyDetail.verified === 1">
                  {{ partnerCompanyDetail.verified_time + ' ( ' + partnerCompanyDetail.verified_string + ' )' }}
                </template>
                <template v-else>
                  <span
                    class="font-common"
                    style="color: #EA3E3A; font-weight: bold;"
                  >
                    Belum Terverifikasi
                  </span>
                </template>
              </v-col>
            </v-row>

            <v-row class="mt-n4">
              <v-col
                cols="12"
                sm="2"
                class="font-common pt-8 pb-4"
              >
                Status Perusahaan
              </v-col>

              <v-col
                cols="12"
                sm="2"
                class="font-common pt-4 pb-0 pr-10"
                style="font-weight: bold;"
              >
                <v-select
                  v-model="partnerCompanyDetail.status"
                  color="purple"
                  :items="options"
                  class="font-common"
                  @change="updateStatus"
                />
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-6"
            elevation="0"
          >
            <v-row>
              <v-col
                class="font-common"
                style="font-weight: bold;"
              >
                Dokumen
              </v-col>
            </v-row>

            <v-row
              v-for="(v, i) in partnerCompanyDetail.documents"
              :key="i"
            >
              <v-col
                cols="12"
                sm="4"
                class="font-common py-1"
              >
                {{ v.name }}
              </v-col>
              <v-col
                cols="12"
                sm="5"
                class="font-common py-2"
                style="font-weight: bold;"
              >
                {{ v.file_name }}
                <v-icon
                  class="ml-2"
                  style="cursor: pointer;"
                  @click="downloadFile(v.file_path)"
                >
                  mdi-eye
                </v-icon>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col class="px-0 py-0">
        <div>
          <v-card
            class="px-6 py-6"
            elevation="0"
          >
            <v-row>
              <v-col
                class="font-common"
                style="font-weight: bold;"
              >
                Sub Akun
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nama
                        </th>
                        <th class="text-left">
                          Email
                        </th>
                        <th class="text-left">
                          No.Hp
                        </th>
                        <th class="text-left">
                          Peran
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(v, i) in partnerCompanyDetail.subaccount"
                        :key="i"
                      >
                        <td class="font-common">
                          {{ v.name }}
                        </td>
                        <td class="font-common">
                          {{ v.email }}
                        </td>
                        <td class="font-common">
                          {{ v.phone }}
                        </td>
                        <td class="font-common">
                          {{ v.pos_nama }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {},

    data: () => ({
      // Section Data General
      partnerCompanyDetail: {
        address: '',
        akot_string: '',
        apro_string: '',
        bank_has: true,
        bank_name: '',
        bank_number: '',
        bank_owner: '',
        bank_verified: true,
        contract_validity_date: '',
        director_name: '',
        director_phone: '',
        documents: [],
        hash: '',
        name: '',
        name_legal: '',
        owner_name: '',
        phone: '',
        status: 1,
        status_string: 'Aktif',
        subaccount: [],
        verified: 1,
        verified_string: 'Terverifikasi',
        verified_time: '',
      },

      // Section status perusahaan
      options: [
        { text: 'Aktif', value: 1 },
        { text: 'Non-Aktif', value: 2 },
      ],

      // Section temporary data
      tmpData: {
        // image: null,
      },

      // Section Pagination
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {

    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('xxx')) {
        //   this.xxx = true
        // }

        // const vm = this

        const requestBody = {
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/t/partner/company/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.partnerCompanyDetail = res.data.data.detail
          }
        }).catch((e) => {
          console.log(e.response)
        })
      },

      updateStatus () {
        const requestBody = {
          prt_comp_hash: this.$route.params.id,
          new_status: this.partnerCompanyDetail.status,
        }

        axios.post('/t/partner/company/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      navBack () {
        this.$router.push({ name: 'PartnerCompany' })
      },

      downloadFile (link) {
        window.open(link, '_blank')
      },

    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #808080;
}
</style>
